<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        {{ `Protocolo Fsmi: ${selected.name}` }}
      </v-card-title>
      <v-card-text class="my-5">
        <tracking-impaction-point-input-sides
          v-if="selected"
          :key="selected.id"
          :value="sides"
          :tracking-point="selected.points.trackingPoint"
          :impaction-point="selected.points.impactionPoint"
          class="grey"
          @input="
            (value) => setValuesDiagnosePar({ value, property: 'pointsSides' })
          "
        />
        <property-value
          v-if="selected.description"
          property="Descrição"
          :value="selected.description"
        />
        <property-value
          v-if="selected.recommendations"
          property="Recomendações"
          :value="selected.recommendations"
        />
        <select-par-properties
          v-model="diagnosePar.symptoms"
          label="Sintomas"
          :items="selected.symptoms"
        />
        <comments v-model="comments" />
      </v-card-text>
      <div class="mt-5 d-flex flex-column">
        <v-btn color="secondary" x-large @click="addToDiagnose">
          Adicionar ao Diagnóstico
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    comments: import(
      "@/domains/therapy/shared/presentation/components/Comments"
    ),
  },
  data: () => ({
    diagnosePar: {},
    comments: {},
    sides: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.fsmiProtocol;
      },
      set(value) {
        this.$store.commit("dialogs/setFsmiProtocol", value);
      },
    },
    selected() {
      return this.$store.state.fsmiProtocol.selected;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    setValuesDiagnosePar(value) {
      this.sides = value;
    },
    async addToDiagnose() {
      try {
        const { therapistComments, patientComments } = this.comments;
        const { trackingPointSide, impactionPointSide } = this.sides;

        const diagnosePar = {
          therapistComments,
          patientComments,
          ...this.diagnosePar,
          par: {
            ...this.selected,
          },
          trackingPointSide,
          impactionPointSide,
        };

        await this.$store.dispatch("diagnosis/addParToDiagnose", diagnosePar);
        this.$toasted.global.success({
          message: "Par de intoxicação adicionado ao diagóstico",
        });
        this.dialog = false;
      } catch (error) {
        this.$toasted.global.error({
          message: error.message
            ? error.message
            : "Erro interno ao adicionar ao diagnóstico",
        });
      }
    },
  },
};
</script>

<style scoped></style>
